import './Footer.scss';

const Footer = () => {
  return (
    <div className='footer'>
      <div className='top'>
        <div className='item'>
          <h1>Categories</h1>
          <span>Women</span>
          <span>Men</span>
          <span>Shoes</span>
          <span>Accessories</span>
          <span>New Arrivals</span>
        </div>
        <div className='item'>
          <h1>Links</h1>
          <span>Women</span>
          <span>Men</span>
          <span>Shoes</span>
          <span>Accessories</span>
          <span>New Arrivals</span>
        </div>
        <div className='item'>
          <h1>Title</h1>
          <span>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
            aliquam, quam in pharetra scelerisque, elit nisl egestas ex, et
            suscipit lorem arcu sit amet sapien. Pellentesque interdum sapien
            magna. Curabitur vitae est ultrices, efficitur sem eu, vehicula
            magna. Phasellus convallis urna sit amet augue commodo dignissim.
            Sed malesuada sed arcu eu iaculis. Suspendisse dignissim quam eget
          </span>
        </div>
        <div className='item'>
          <h1>Title</h1>
          <span>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
            aliquam, quam in pharetra scelerisque, elit nisl egestas ex, et
            suscipit lorem arcu sit amet sapien. Pellentesque interdum sapien
            magna. Curabitur vitae est ultrices, efficitur sem eu, vehicula
            magna. Phasellus convallis urna sit amet augue commodo dignissim.
            Sed malesuada sed arcu eu iaculis. Suspendisse dignissim quam eget
          </span>
        </div>
      </div>
      <div className='bottom'>
        <div className='left'>
          <span className='logo'>Lamastore</span>
          <span className='copyright'>Copyright 2023 all rights reserved</span>
        </div>
        <div className='right'>Payment images here</div>
      </div>
    </div>
  );
};

export default Footer;
