import { Link } from 'react-router-dom';
import './Categories.scss';
const Categories = () => {
  return (
    <div className='categories'>
      <div className='col'>
        <div className='row'>
          <img
            src='https://loremflickr.com/800/1600/sale?random=1&lock=2'
            alt=''
          />
          <button>
            <Link className='link' to='/products/1'>
              Sale
            </Link>
          </button>
        </div>
        <div className='row'>
          <img
            src='https://loremflickr.com/800/1600/Woman?random=1&lock=4'
            alt=''
          />
          <button>
            <Link className='link' to='/products/1'>
              Women
            </Link>
          </button>
        </div>
      </div>
      <div className='col'>
        <div className='row'>
          <img
            src='https://loremflickr.com/800/1600/man?random=1&lock=4'
            alt=''
          />
          <button>
            <Link className='link' to='/products/1'>
              Men
            </Link>
          </button>
        </div>
      </div>
      <div className='col col-l'>
        <div className='row'>
          <div className='col'>
            <div className='row'>
              <img
                src='https://loremflickr.com/800/1600/children?random=4&lock=13'
                alt=''
              />
              <button>
                <Link className='link' to='/products/1'>
                  Children
                </Link>
              </button>
            </div>
          </div>
          <div className='col'>
            <div className='row'>
              <img
                src='https://loremflickr.com/800/1600/adidas?random=1&lock=7'
                alt=''
              />
              <button>
                <Link className='link' to='/products/1'>
                  Toys
                </Link>
              </button>
            </div>
          </div>
        </div>
        <div className='row'>
          <img
            src='https://loremflickr.com/800/1600/junk?random=1&lock=4'
            alt=''
          />
          <button>
            <Link className='link' to='/products/1'>
              Stuff
            </Link>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Categories;
