import Card from '../Card/Card';
import './FeaturedProducts.scss';
import useFetch from '../../hooks/useFetch';

const FeaturedProducts = ({ type }) => {
  const { data, loading, error } = useFetch(
    `/products?populate=*&[filters][type][$eq]=${type}`
  );

  console.log(data);

  return (
    <div className='featuredproducts'>
      <div className='top'>
        <h1>{type} Products</h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
          bibendum magna et nisi gravida, eget tempus enim volutpat. Integer est
          ligula, blandit eu tincidunt vel, eleifend sit amet ligula. Sed
          ullamcorper ligula lorem, id feugiat urna faucibus quis. Sed lectus
          massa, tristique vitae metus ut, elementum ullamcorper augue.
          Pellentesque neque lectus, gravida eu sodales nec, hendrerit et odio.
          In ut justo neque. Pellentesque vehicula nulla quis lectus aliquam
          porttitor.
        </p>
      </div>
      <div className='bottom'>
        {error
          ? 'Something went wrong!'
          : loading
          ? 'loading'
          : data?.map((item) => <Card item={item} key={item.id} />)}
      </div>
    </div>
  );
};

export default FeaturedProducts;
