import WestOutlinedIcon from '@mui/icons-material/WestOutlined';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import './Slider.scss';
import { useState } from 'react';
const Slider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const data = [
    'https://images.pexels.com/photos/12964228/pexels-photo-12964228.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260',
    'https://images.pexels.com/photos/634281/pexels-photo-634281.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260',
    'https://images.pexels.com/photos/2521559/pexels-photo-2521559.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260',
  ];

  const prevSlide = () => {
    setCurrentSlide(currentSlide === 0 ? 2 : (prev) => prev - 1);
  };

  const nextSlide = () => {
    setCurrentSlide(currentSlide === 2 ? 0 : (prev) => prev + 1);
  };
  return (
    <div className='slider'>
      <div
        className='container'
        style={{ transform: `translateX(-${currentSlide * 100}vw)` }}
      >
        <img src={data[0]} alt='' />
        <img src={data[1]} alt='' />
        <img src={data[2]} alt='' />
      </div>
      <div className='icons'>
        <div className='icon' onClick={prevSlide}>
          <WestOutlinedIcon />
        </div>
        <div className='icon'>
          <EastOutlinedIcon onClick={nextSlide} />
        </div>
      </div>
    </div>
  );
};

export default Slider;
